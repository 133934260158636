
import { defineComponent, ref, onMounted, watch, computed } from 'vue';
import { useRoute } from 'vue-router';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import ApiService from '@/core/services/ApiService';
import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
import { reinitializeComponents } from '@/core/plugins/keenthemes';
import ConfirmModal from '@/components/modals/ConfirmModal.vue';
import FilterModal from '@/components/modals/FilterModal.vue';
import { hideModal } from '@/core/helpers/dom';
import { edit } from '@/assets/ts/_utils/TableUtils';

export default defineComponent({
  name: 'customers-listing',
  components: {
    Datatable,
    ConfirmModal,
    FilterModal,
  },
  props: {
    header: {
      type: Array,
      required: true,
    },
    modelValue: {
      type: Array,
    },
    breadcrumbs: {
      type: Array,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    endpoint: {
      type: String,
      default: '',
    },
    loadingProp: {
      type: Boolean,
      default: false,
    },
    currentIndex: {
      type: Number,
      default: -1,
    },
    customEdit: {
      type: Boolean,
      default: false,
    },
    customProperties: {
      type: Array,
    },
    url: {
      type: String,
      default: '',
    },
    canCreate: {
      type: Boolean,
      default: true,
    },
    defaultSearch: {
      type: String,
      default: 'name',
    },
    filterData: {
      type: Array,
      default: () => [],
    },
    hasQueryParam: {
      type: Boolean,
      default: false,
    },
    endPointWithOutQuery: {
      type: String,
    },
    hasDynamicBreadcrumbs: {
      type: Boolean,
      default: false,
    },
    withSearch: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'change-index',
    'update:modelValue',
    'update-loading',
    'update-with-search',
  ],
  setup(props, { emit }) {
    const route = useRoute();
    const tableHeader = ref([...props.header]);
    const search = ref('');
    const showSearch = props.withSearch
      ? computed({
          get: () => props.withSearch,
          set: (value) => emit('update-with-search', value),
        })
      : ref(false);
    const loading = props.loadingProp
      ? computed({
          get: () => props.loadingProp,
          set: (value) => emit('update-loading', value),
        })
      : ref(false);
    const currentId = ref(-1);
    const currentItem = ref({});
    const modelRef: any = ref(null);
    const total = ref(0);
    const tableRef = ref<any>(null);
    const rowsPerPage = ref(10);
    const currentPage = ref(1);
    const params = ref('');
    const querySymbol = props.hasQueryParam ? '&' : '?';
    let data;

    const endpoint = !props.endPointWithOutQuery
      ? props.endpoint
      : props.endPointWithOutQuery;

    if (props.modelValue) {
      data = computed({
        get: () => props.modelValue,
        set: (value) => emit('update:modelValue', value),
      });
    } else {
      data = ref<any>([]);
    }

    const getData = async (page = 1, rowsPerPage = 10) => {
      loading.value = true;
      const queryCondition: any = {
        page,
        pageSize: rowsPerPage,
      };

      if (search.value) {
        queryCondition[`${props.defaultSearch}`] = search.value;
      }
      const response = await ApiService.get(
        `${props.endpoint}${querySymbol}${new URLSearchParams(
          queryCondition
        )}&${params.value}`
      );

      data.value = response.data.datas;
      total.value = response.data.total;

      reinitializeComponents();
      loading.value = false;
    };

    const setCurrentItem = (val: any, index: number) => {
      currentItem.value = val;
      emit('change-index', index);
    };

    watch(
      () => [
        tableRef.value?.pagination.rowsPerPage,
        tableRef.value?.pagination.page,
        params.value,
      ],
      async ([rows, page]) => {
        currentPage.value = page;
        rowsPerPage.value = rows;

        if (rowsPerPage.value + 1 > total.value) {
          currentPage.value = 1;
        }

        emit('change-index', -1);

        if (!props.modelValue) {
          await getData(currentPage.value, rowsPerPage.value);
        }
      }
    );

    watch(
      () => params.value,
      async () => {
        currentPage.value = 1;
        tableRef.value.pagination.page = 1;

        await getData(currentPage.value, rowsPerPage.value);
      }
    );

    const tableData = computed(() => {
      if (props.customProperties) {
        const computedProperties: any = [...props.customProperties];
        return data.value.map((item, index) => {
          return {
            no: index + 1 + rowsPerPage.value * (currentPage.value - 1),
            ...(computedProperties?.reduce(
              (obj, item2: any) =>
                Object.assign(obj, {
                  [item2.key]: item
                    ? item2.value.reduce((prev, cur) => prev?.[cur], item)
                    : '',
                }),
              {}
            ) as Record<string, unknown>),
            ...item,
          };
        });
      } else {
        return [
          ...data.value.map((item, index) => ({
            no: index + 1 + rowsPerPage.value * (currentPage.value - 1),
            ...item,
          })),
        ];
      }
    });

    onMounted(async () => {
      if (!props.hasDynamicBreadcrumbs) {
        setCurrentPageBreadcrumbs(
          props.breadcrumbs[0] as string,
          props.breadcrumbs[1] as Array<Record<string, unknown> | string>,
          props.breadcrumbs[2] as Record<string, unknown>
        );
      }
    });

    const querySearch = async (queryString, cb) => {
      if (!queryString) return;

      const querySymbol2 = props.endPointWithOutQuery ? '&' : '?';

      const searchString = queryString.trim();

      const { data } = await ApiService.get(
        `${props.endpoint}${querySymbol2}${props.defaultSearch}=${searchString}&${params.value}`
      );

      const results = data.datas.map((item) => ({
        value: item.name,
      }));

      cb(results);
    };

    const deleteRow = async () => {
      data.value.forEach(async (item) => {
        if (item.id === currentId.value) {
          await ApiService.delete(`${endpoint}/${currentId.value}`);
          data.value = data.value.filter(
            (item2) => item2.id !== currentId.value
          );
          total.value -= 1;
        }
      });
      hideModal(modelRef.value.$el);
    };

    const editItem = async () => {
      await edit(currentItem.value, endpoint);
      tableData.value[props.currentIndex] = currentItem.value;
      emit('change-index', -1);
    };

    return {
      tableData,
      tableHeader,
      search,
      route,
      loading,
      currentId,
      modelRef,
      deleteRow,
      currentItem,
      editItem,
      total,
      tableRef,
      rowsPerPage,
      currentPage,
      getData,
      params,
      querySearch,
      showSearch,
      setCurrentItem,
    };
  },
});
